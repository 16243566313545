import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="231" height="11" viewBox="0 0 231 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.9293 10.1458C38.1586 9.20128 3.19951 10.1189 0.659678 9.8305C-1.41336 9.44831 1.92402 2.85333 3.85276 1.88705C4.52884 1.53964 5.31308 1.49146 7.10895 1.65652C9.95639 1.96503 46.7898 1.49885 46.7898 1.49885C47.2754 1.70397 48.7367 1.02224 49.0039 1.27112C49.8455 1.85259 109.547 1.26701 114.638 1.25252C117.725 1.24393 115.282 1.62984 118.915 1.48323C126.658 1.19162 143.529 1.55415 149.396 1.21166C154.091 0.928774 155.104 0.919506 159.609 1.17643C164.213 1.40826 188.699 1.1799 191.499 0.725255C192.185 0.600416 192.711 0.646716 192.71 0.792956C192.71 0.824747 209.969 0.99148 210.048 1.1062C210.177 1.25288 210.702 1.3246 211.189 1.22452C212.093 1.07499 230.466 0.584243 230.752 1.01122C231.651 2.23508 229.103 7.42754 226.964 8.68559C224.776 10.0325 212.792 8.47213 203.152 9.25324L158.281 9.18344L148.662 9.65941C147.562 9.21695 143.321 10.3089 140.763 9.51176C139.682 9.19653 132.04 9.12605 130.351 9.45094C128.882 9.70028 89.7896 8.92526 85.7769 9.79543C78.6335 9.03359 49.6491 9.99061 42.9293 10.1458Z" fill="#FFBA00" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="217" height="11" viewBox="0 0 217 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.2832 10.1368C35.8068 9.19329 3.00228 10.1182 0.619065 9.83036C-1.32611 9.44861 1.80693 2.85293 3.61699 1.88625C4.25148 1.5387 4.98739 1.49035 6.67253 1.65503C9.34439 1.96296 43.9076 1.48906 43.9076 1.48906C44.3632 1.69407 45.7346 1.01203 45.9852 1.26086C46.7748 1.84215 102.796 1.24407 107.574 1.22851C110.47 1.21928 108.178 1.60571 111.587 1.45833C118.853 1.1651 134.684 1.5241 140.189 1.18038C144.595 0.896507 145.545 0.887028 149.773 1.14301C154.093 1.37387 177.07 1.14038 179.698 0.685154C180.341 0.560172 180.834 0.606361 180.834 0.752601C180.834 0.784393 197.029 0.94751 197.103 1.06221C197.224 1.20886 197.717 1.28048 198.174 1.1803C199.022 1.03058 216.262 0.535981 216.531 0.962903C217.374 2.18657 214.982 7.37956 212.975 8.63805C210.922 9.9854 199.676 8.42756 190.631 9.21069L148.525 9.15029L139.499 9.62827C138.467 9.18604 134.487 10.2789 132.086 9.48228C131.072 9.16727 123.901 9.09839 122.317 9.42364C120.938 9.67329 84.2554 8.90645 80.4899 9.77747C73.7869 9.01712 46.5889 9.98021 40.2832 10.1368Z" fill="#FFBA00" />
    </svg>
  </span>
)
